import { FC } from "react"

interface ICartIcon {
    color: string 
    size: string 
    margin?: string 
    transform?: string
    onClickCB:() => void 
    disabled: Boolean
}

export const  CartIcon: FC<ICartIcon> = ({ color, size, margin, transform, onClickCB, disabled }:ICartIcon) => {

    return <>
        <svg style={{ margin: `${margin}`, transform: `${transform}` }}
            fill={disabled ? '#D8D1CA' : color}
            onClick={() => disabled ? false : onClickCB()}
            width={size} height={size}
            xmlns="http://www.w3.org/2000/svg"
            id="Bold" viewBox="0 0 24 24" >

            <path d="M22.713,4.077A2.993,2.993,0,0,0,20.41,3H4.242L4.2,2.649A3,3,0,0,0,1.222,0H1A1,1,0,0,0,1,2h.222a1,1,0,0,1,.993.883l1.376,11.7A5,5,0,0,0,8.557,19H19a1,1,0,0,0,0-2H8.557a3,3,0,0,1-2.82-2h11.92a5,5,0,0,0,4.921-4.113l.785-4.354A2.994,2.994,0,0,0,22.713,4.077Z" /><circle cx="7" cy="22" r="2" /><circle cx="17" cy="22" r="2" />


        </svg>




    </>
}